.blogpost-main .blogpost-post{
    display: flex;
    justify-content: center;
    padding: 50px 30px;
}

.blogpost-main .blogpost-post .blogpost-post-content{
    width: 100%;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.blogpost-main .blogpost-post .blogpost-post-content span.date{
    font-size: 14px;
    color: #FF1056;
    font-weight: 600;
}

.blogpost-main .blogpost-post .blogpost-post-content p.post-title{
    font-size: 40px;
    font-weight: 500;
    color: #00E23B;
    text-transform: uppercase;
    font-family: transducer;
    line-height: 40px;
}

.blogpost-main .blogpost-post .blogpost-post-content p.post-title span{
    font-size: 40px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    font-family: transducer;
    line-height: 40px;
}

.blogpost-main .blogpost-post .blogpost-post-content p.post-abr-content{
    font-size: 16px;
    font-weight: 300;
    opacity: 0.6;
    color: #fff;
    max-width: 900px;
}
