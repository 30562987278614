.posts-main{
    color: #fff;
    display: flex;
    flex-direction: column;
}

/* Title Holder */

.posts-main .title-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 130px;
}

.posts-main h2{
    color: #16ff00;
    font-size: 40px;
    font-weight: 500;
    font-family: transducer;
}

.posts-main h2 span{
    color: #FFF;
    font-weight: 500;
    font-family: transducer;
}

/* Posts Holder */
.posts-holder{
    width: 100%;
    margin-top: 50px;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
}

.posts-holder ul{
    width: 600px;
    display: flex;
    flex-direction: column;
}

.posts-holder li{
    list-style: none;
    margin: 30px 0px;
    border: solid 2px #363636;
    border-radius: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.posts-holder .post-header{
    width: 100%;
    height: 30px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}

.posts-holder .post-header span{
    font-weight: 600;
    letter-spacing: 1px;
}

.posts-holder .post-header span{
    font-size: 12px;
}

.posts-holder li img{
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    border-bottom: solid 2px #363636;
}

.posts-holder li .caption{
    font-size: 14px;
    width: 100%;
    padding: 20px 20px;
    display: flex;
    line-height: 18px;
    opacity: 0.5;
}

.posts-holder li a{
    text-decoration: none;
    color: #16ff00;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    width: 100%;
    text-align: center;
}