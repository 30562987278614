footer{
    width: 100%;
    background-color: #000;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
}

footer .partners{
    border-top: solid 1px #1d1d1d;
    border-bottom: solid 1px #1d1d1d;
    padding: 40px 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

footer .partners-content{
    width: 100%;
    max-width: 1080px;
}

footer .partners-content h2{
    color: #ff1056;
    text-transform: uppercase;
    font-family: transducer;
    font-weight: 500;
    font-size: 30px;
}

footer .partners-content h2 span{
    color: #fff;
    text-transform: uppercase;
    font-family: transducer;
    font-weight: 500;
}

footer .footer-box{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 70px 30px;
    position: relative;
}

footer .footer-box .footer-content{
    width: 100%;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
}

footer .footer-box .footer-content h2{
    color: #00e23b;
    text-transform: uppercase;
    font-family: transducer;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
}

footer .footer-box .footer-content h2 span{
    color: #fff;
    text-transform: uppercase;
    font-family: transducer;
    font-weight: 500;
}

footer .footer-box .footer-content a{
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: 600;
    margin-top: 45px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 180px;
}

footer .footer-box .footer-content a div{
    width: 9px;
    height: 9px;
    border: solid 2px #00e23b;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}


footer .footer-box .footer-content a div div{
    position: absolute;
    animation-name: linkAnimation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    display: none;
}

footer .footer-box .footer-content a:hover div div{
    display: block;
}


@keyframes linkAnimation {
    0%   {width: 9px; height: 9px; opacity: 1;}
    100% {width: 25px; height: 25px; opacity: 0;}
  }


footer .footer-box .footer-content img{
    width: 200px;
    margin-top: 200px;
    z-index: 1;
}

footer .footer-box .footer-content > span{
    font-size: 13px;
    color: #fff;
    opacity: 0.5;
    line-height: 15px;
    margin-top: 10px;
}

footer .hand{
    position: absolute;
    right: -50px;
    bottom: 50px;
    z-index: 0;
}

/* Media Query */

@media only screen and (max-width: 769px) {
    footer .hand{
        right: -340px;
        bottom: -190px;
        transform: rotate(45deg);
    }
}



