.blog-main .page-title{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 30px;
}

.blog-main .page-title .page-title-content{
    width: 100%;
    max-width: 1080px;
    border-bottom: solid 1px #404040;
    padding-top: 150px;
    padding-bottom: 70px;
}

.blog-main .page-title .page-title-content h2{
    font-size: 40px;
    font-weight: 500;
    font-family: transducer;
    color: #00e23b;
}

.blog-main .page-title .page-title-content h2 span{
    font-weight: 500;
    font-family: transducer;
    color: #fff;
}

/* Blog Posts */

.blog-main .blog-posts{
    display: flex;
    justify-content: center;
    padding: 0px 30px;
}

.blog-main .blog-posts-content{
    width: 100%;
    max-width: 1080px;
    color: #fff;
    padding: 90px 0px;
}

.blog-main .blog-posts-content ul{
    display: flex;
    flex-direction: column;
    gap: 70px;
}

.blog-main .blog-posts-content ul li{
    list-style: none;
    display: flex;
    gap: 50px;
    cursor: pointer;
}

.blog-main .blog-posts-content ul li:hover p.post-title{
    text-decoration: underline;
}

.blog-main .blog-posts-content ul a{
    text-decoration: none;
    color: currentColor;
}

.blog-main .blog-posts-content ul li .post-details{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.blog-main .blog-posts-content ul li img{
    width: 172px;
    height: 172px;
    border-radius: 15px;
}

.blog-main .blog-posts-content ul li span.date{
    font-size: 14px;
    color: #FF1056;
    font-weight: 600;
}

.blog-main .blog-posts-content ul li p.post-title{
    font-size: 20px;
    font-weight: 500;
}

.blog-main .blog-posts-content ul li p.post-abr-content{
    font-size: 16px;
    font-weight: 300;
    max-width: 650px;
    opacity: 0.6;
}

.blog-main .blog-posts-content ul li p.post-abr-content::after{
    content: "...";
}


/* Media Query */
@media only screen and (max-width: 769px) {
    .blog-main .blog-posts-content ul li img{
        display: none;
    }
}