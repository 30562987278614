.groupkhouse-main{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 30px 200px 30px;
    flex-direction: column;
    gap: 90px;
}

.groupkhouse-main .groupkhouse-title{
    font-size: 30px;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 80px;
    width: 100%;
    max-width: 1080px;
}

.groupkhouse-main .groupkhouse-title h2{
    font-weight: 600;
    text-transform: uppercase;
    color: #16ff00;
    width: 100%;
    text-align: center;
}

.groupkhouse-main .groupkhouse-title h2 span{
    font-weight: 600;
    color: #fff;
}

.groupkhouse-main .groupkhouse-title ul{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
    padding: 0px 50px;
}

.groupkhouse-main .groupkhouse-title ul li{
    list-style: none;
}

.groupkhouse-main .groupkhouse-title ul li img{
    width: 200px;
}

.groupkhouse-main .groupkhouse-title ul li:nth-child(2) img{
    width: 250px;
}

.groupkhouse-main .groupkhouse-title ul li:nth-child(3) img{
    width: 130px;
}

.groupkhouse-main .groupkhouse-title > span{
    font-size: 18px;
    width: 100%;
    text-align: center;
    line-height: 30px;
    opacity: 0.8;
}

.groupkhouse-main .group-cases{
    width: 100%;
    max-width: 1080px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}

.groupkhouse-main .group-cases li{
    width: 340px;
    height: 450px;
    background-color: #333;
    list-style: none;
}


/* Media Query */

@media only screen and (max-width: 1080px) {
    .groupkhouse-main .groupkhouse-title ul{
        flex-direction: column;
    }
}