span.bekhouse{
    color: #FF0062;
    font-size: 100px;
    font-weight: 800;
}

.about-main{
    width: 100%;
    background: url("../../assets/about/about-background.png");
    background-repeat: no-repeat;
    background-position: -40px 0px;
    padding: 100px 65px;
    display: flex;
    justify-content: right;
}

/* Content */

.about-content{
    display: flex;
    flex-direction: column;
}

.about-title{
    color: #FF0062;
    font-size: 70px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: transducer;
    margin-bottom: 35px;
}

.about-title > span{
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    font-family: transducer;
}

.about-mobile-title{
    display: none;
    color: #16ff00;
    font-size: 100px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 35px;
    font-family: transducer;
}

.about-mobile-title > span{
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    font-family: transducer;
}

.about-content p{
    color: #fff;
    font-size: 25px;
    max-width: 600px;
    margin-bottom: 26px;
}

.about-content p span.bold{
    font-weight: 800;
}

.about-content p span.pink{
    color: #FF0062;
}

.about-content p span{
    color: #fff;
    font-size: 25px;
    margin-top: 30px;
}

.about-content form{
    margin-top: 20px;
}

.about-content form input{
    width: 198px;
    height: 46px;
    background: #fff;
    border-radius: 5px;
    padding-left: 10px;
    margin-right: 21px;
}

.about-content form button{
    width: 120px;
    height: 46px;
    background: #fff;
    border-radius: 5px;
    margin-right: 21px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 800;
    color: #495667;
    letter-spacing: 1.5px;
}

.about-content form button:hover{
    background: #FF0062;
    color: #fff;
}


/* Media Query */

@media only screen and (max-width: 1025px) {
    .about-content h2{
        font-size: 80px;
    }
    .about-content p{
        font-size: 20px;
        line-height: 35px;
    }
    .about-content span.bold{
        font-size: 20px;
    }
    .about-content span.pink{
        font-size: 20px;
    }
}


@media only screen and (max-width: 769px) {
    .about-main{
        width: 100%;
        background: url("../../assets/about/aboutMobileBackground.png");
        background-repeat: repeat-y;
        background-position: center -40px;
        background-size: 100%;
        padding: 100px 20px;
        display: flex;
        justify-content: right;
    }
    .about-content p{
        font-size: 18px;
        line-height: 35px;
    }
    .about-content span.bold{
        font-size: 18px;
    }
    .about-content span.pink{
        font-size: 18px;
    }
    .about-mobile-title{
        display: block;
    }
    .about-title{
        display: none;
    }
    .about-content span.pink{
        color: #16ff00;
    }
    .about-content h2{
        font-size: 45px;
    }

}