.welcome-main{
    width: 100%;
    position: relative;
    height: 100vh;
    background: url("../../assets/welcome/welcomeBanner.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.opacity-mask{
    position: absolute;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), #000);
    bottom: 0;
    left: 0;
}

.opacity-mask-full{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  bottom: 0;
  left: 0;
  opacity: 0.4;
}

.welcome-logo-content{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-logo-content h1{
  color: #FF1056;
  font-size: 9vw;
  text-transform: uppercase;
  font-family: transducer;
  font-weight: 600;
  display: flex;
  gap: 30px;
  animation-name: logoshow;
  animation-duration: 2s;
  animation-delay: 0.3s;
  opacity: 0;
  top: 100px;
  animation-fill-mode: forwards;
  position: relative;
}

@keyframes logoshow {
  0%   {opacity: 0; top: 100px;}
  100% {opacity: 1; top: 0;}
}

.welcome-logo-content h1 span{
  color: #fff;
  text-transform: uppercase;
  font-family: transducer;
  font-weight: 600;
}

.welcome-banner{
    width: 100%;
}

.welcome-logo{
    width: 547px;
    position: relative;
    animation-name: logoshow;
    animation-duration: 2s;
    animation-delay: 0.2s;
    opacity: 0;
    top: 100px;
    animation-fill-mode: forwards;
}

@keyframes logoshow {
    0%   {opacity: 0; top: 100px;}
    100% {opacity: 1; top: 0;}
  }

/* Scroll Down */
.scroll-downs {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
    width : 29px;
    height: 43px;
    animation-name: scrollicon;
    animation-duration: 3s;
    animation-delay: 2s;
    opacity: 0;
    animation-fill-mode: forwards;
  }

  @keyframes scrollicon {
    0%   {opacity: 0; bottom: 80px;}
    100% {opacity: 1; bottom: 40px;}
  }

  .mousey {
    width: 3px;
    padding: 2px 11px;
    height: 35px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
  }
  .scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
  }
  @keyframes scroll {
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px); opacity: 0;}
  }


/* Media Query */

@media only screen and (max-width: 1080px) {
  /* .welcome-logo-content h1{
    font-size: 60px;
    gap: 15px;
  } */
}

@media only screen and (max-width: 1025px) {
    .welcome-logo{
        width: 540px;
    }
}

@media only screen and (max-width: 768px) {
    .welcome-logo{
        width: 343px;
    }

    /* .welcome-logo-content h1{
      font-size: 30px;
      gap: 10px;
    } */
}

