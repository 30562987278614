.cases-main{
    color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.cases-main .cases-title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cases-main .cases-title h2{
    font-size: 25px;
    font-family: transducer;
    font-weight: 600;
    text-transform: uppercase;
    padding: 250px 30px;
    color: #FF1056;
    text-align: center;
}

.cases-main .cases-title h2 span{
    font-weight: 600;
    color: #fff;
    font-family: transducer;
}

.cases-main ul.cases{
    display: flex;
    width: 100%;
    max-width: 1080px;
    margin-bottom: 100px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cases-main ul.cases li{
    list-style: none;
    width: 46%;
    margin-bottom: 50px;
}

.cases-main ul.cases li:nth-child(even){
    margin-top: 200px;
}

.cases-main ul.cases li img{
    width: 100%;
}

/* Media Query */

@media only screen and (max-width: 769px) {
    .cases-main ul.cases li:nth-child(even){
        margin-top: 70px;
    }
}