.mindset-main{
    width: 100%;
    position: relative;
    height: 768px;
    background: url("../../assets/mindset/mindsetBackground.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.mindset-opacity-background{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #000;
    opacity: 0.4;
}

img.background-image{width: 100%;}
img.background-image-letters{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}


.mindset-text-holder{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    padding: 0px 65px;
}

.mindset-text-holder p{
    font-size: 90px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 18px;
    width: 1130px;
    font-family: transducer;
    line-height: 110px;
}

/* Media Query */

@media only screen and (max-width: 1025px) {
    .mindset-text-holder p{
        font-size: 70px;
        line-height: 84px;
        letter-spacing: 5px;
    }
    .background-image{
        height: 500px;
    }
}

@media only screen and (max-width: 768px) {
    .mindset-text-holder{
        padding: 0px 20px;
    }
    .mindset-text-holder p{
        font-size: 45px;
        line-height: 64px;
        letter-spacing: 3px;
    }
}