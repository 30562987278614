.metahumans-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 60px 65px;
    background: url("../../assets/metahumans/background-metahumans.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    align-items: center;
}

.meta-title{
    width: 100%;
    max-width: 800px;
}

.metahumans-main .list-holder{
    display: flex;
    max-width: 1080px;
    width: 100%;
}

.meta-image{
    display: none;
    width: 100%;
    height: 348px;
    background: url("../../assets/metahumans/background-metahumans.gif");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.metahumans-list{
    width: 100%;
}

.metahumans-item{
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 360px;
}

.metahumans-item > img{
    width: 95%;
}

.metahumans-item-name{
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.metahumans-item-name p::before{
    width: 2.7px;
    height: 8.3px;
    content: '';
    background: url("../../assets/metahumans/icon-points.png");
    display: block;
    left: -10px;
    top: 16px;
    position: relative;
}

.metahumans-item-name p{
    font-size: 17px;
    font-weight: 800;
}

.metahumans-item-name span{
    margin-top: 0px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
}

p.item-desc{
    font-size: 12px;
    padding: 0px 15px;
    line-height: 20px;
    width: 98%;
}

button.slick-arrow.slick-disabled{
    opacity: 0;
}

/* Media Query */

@media only screen and (max-width: 769px) {
    p.item-desc{
        width: 240px;
    }
    .metahumans-list{
        width: 260px;
    }
    .list-holder{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
        margin-top: 30px;
    }
    .metahumans-item-name{
        align-items: center;
    }
    .metahumans-main{
        padding: 60px 20px;
    }
}



