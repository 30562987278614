@import url("https://use.typekit.net/nnm8oiu.css");

body{
	background-color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure,
fieldset,
img,
p {
    margin: 0;
    padding: 0;
    border: 0;
}
* {
    margin: 0;
    padding: 0px;
    border: 0;
    box-sizing: border-box;
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    font-weight: 300;
    font-family: "Avenir", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6{
  font-family: transducer;
}

body{
  margin: 0;
  padding: 0;
  font-family: "Avenir";
}


/*Scrollbar*/

/* width */
::-webkit-scrollbar {
  width: 5px;
  background: #000;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff0062;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #16ff00;
}


