.successform-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.successform-main h2{
    padding: 50px 0px;
    font-size: 65px;
    color: #16ff00;
    font-weight: 800;
    letter-spacing: 10px;
}

.successform-main p{
    color: #fff;
    font-size: 20px;
}

.successform-main button{
    color: #000;
    font-size: 14px;
    margin-top: 80px;
    padding: 10px 30px;
    background: #16ff00;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 800;
}

.successform-main button:hover{
    background: #FF0062;
}

.successform-main button:hover a{
    color: #fff;
}

.successform-main .floor-digital{
    position: absolute;
    bottom: 0;
}

/* Media Query */

@media only screen and (max-width: 1025px) {
    .successform-main h2{
        font-size: 50px;
    }
    .successform-main p{
        color: #fff;
        font-size: 18px;
    }
}

@media only screen and (max-width: 768px) {
    .successform-main h2{
        font-size: 35px;
    }
    .successform-main p{
        color: #fff;
        font-size: 16px;
    }
}