.header-main{
    width: 100%;
    padding: 30px 65px;
    display: flex;   
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 10;
}

.header-main a{
  display: flex;
  align-items: center;
}

.header-main .logo{
    width: 23px;
    height: 23px;
}

.header-main img{
    position: relative;
    left: -50px;
    animation-fill-mode: forwards;
    animation-name: logo;
    animation-duration: 1s;
    animation-delay: 1s;
    opacity: 0;
}

@keyframes logo {
  0%   {opacity: 0; left: -50px;}
  100% {opacity: 1; left: 0px;}
}

.header-main .menu-holder{
    display: flex;
    align-items: center;
    animation-name: menuShow;
    animation-duration: 1s;
    animation-delay: 1s;
    opacity: 0;
    position: relative;
    right: -50px;
    animation-fill-mode: forwards;
}

@keyframes menuShow {
  0%   {opacity: 0; right: -50px;}
  100% {opacity: 1; right: 0px;}
}

.header-main .menu-holder ul{
    display: flex;
}

.header-main .menu-holder li{
    margin: 0px 13px;
    list-style: none;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    position: relative;
}

.header-main .menu-holder li a{
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

.header-main span.bekhouse-hashtag{
    font-size: 16px;
    color: #fff;
}

/* Menu mobile */

.menu-mobile-open-button{
    display: none;
    width: 21px;
    height: 21px;
    background: url("../../assets/header/icon-menu.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.menu-mobile-open-button:hover{
    animation: rotation 0.4s linear;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }

.menu-mobile-main{
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000;
    align-items: center;
    justify-content: center;
}

.menu-mobile-main ul{
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menu-mobile-main li{
    color: #fff;
    list-style: none;
    font-size: 40px;
    cursor: pointer;
    margin-left: 0;
    margin: 20px 0px;
}

.menu-mobile-main li a{
  color: #fff;
  list-style: none;
  font-size: 40px;
  position: relative;
  text-decoration: none;
}

.menu-mobile-close-button{
    position: absolute;
    top: 30px;
    right: 35px;
    width: 21px;
    height: 21px;
    background-color: #000;
    color: #fff;
    font-size: 20px;
    font-weight: 800;
    cursor: pointer;
}




/* Glitch Effect Hover */

.glitch-hover:hover{
    animation: glitch 1s linear infinite;
}

.glitch-hover:hover:before,
.glitch-hover:hover:after{
  content: attr(title);
  position: absolute;
  left: 0;
}

.glitch-hover:hover:before{
    animation: glitchTop 1s linear infinite;
    clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  }
  
  @keyframes glitchTop{
    2%,64%{
      transform: translate(2px,-2px);
    }
    4%,60%{
      transform: translate(-2px,2px);
    }
    62%{
      transform: translate(13px,-1px) skew(-13deg); 
    }
  }
  
  .glitch-hover:hover:after{
    animation: glitchBotom 1.5s linear infinite;
    clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  }
  
  @keyframes glitchBotom{
    2%,64%{
      transform: translate(-2px,0);
    }
    4%,60%{
      transform: translate(-2px,0);
    }
    62%{
      transform: translate(-22px,5px) skew(21deg); 
    }
  }

@keyframes glitch{
    2%,64%{
      transform: translate(2px,0) skew(0deg);
    }
    4%,60%{
      transform: translate(-2px,0) skew(0deg);
    }
    62%{
      transform: translate(0,0) skew(5deg); 
    }
  }



/* Media Query */

@media only screen and (max-width: 1080px) {
    .menu-mobile-open-button{display: block;}
    .header-main .menu-holder{
        display: none;
    }
    .header-main span.bekhouse-hashtag{
        display: none;
    }
    .header-main{
        justify-content: space-between;
        padding: 30px 35px;
    }
}