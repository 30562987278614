.products-main{
    width: 100%;
    padding: 150px 65px;
    background: url("../../assets/products/productsBackground.png");
    background-repeat: no-repeat;
    background-position: -100px -30px;
    display: flex;
    justify-content: space-between;
}

.products-title-holder{
    display: flex;
    align-items: center;
}

.products-title-holder h3{
    font-size: 80px;
    color: #16ff00;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 95px;
    z-index: 1;
    width: 560px;
    font-family: transducer;
}

.products-title-holder h3 span{
    font-family: transducer;
    font-size: 80px;
    color: #fff;
    font-weight: 500;
}

.products-list{
    display: flex;
    min-width: 520px;
}

.product-list-ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.product-item{
    display: flex;
    flex-direction: column;
    color: #fff;
    align-items: center;
    border-radius: 20px;
    padding: 20px 20px;
    width: 250px;
    margin-top: 10px;
}

.product-item img{
    width: 65px;
}

.product-item h3{
    font-size: 20px;
    font-weight: 800;
    margin-top: 17px;
    text-align: center;
}

.product-item p{
    margin-top: 9px;
    font-size: 10px;
    width: 130px;
    text-align: center;
}



/* Media Query */

@media only screen and (max-width: 1650px) {
    .products-list{
        width: 800px;
    }
  }

@media only screen and (max-width: 1440px) {
    .products-list{
        width: 520px;
    }
}

@media only screen and (max-width: 1148px) {
    .products-main{
        flex-direction: column;
    }
    .products-list{
        width: 100%;
        margin-top: 40px;
        justify-content: space-between;

    }
    .products-title-holder h3{
        font-size: 70px;
        line-height: 83px;
    }
    .products-title-holder h3 span{
        font-size: 70px;
    }
}

@media only screen and (max-width: 768px) {
    .product-list-ul{
        flex-direction: column;
    }
    .products-title-holder{
        justify-content: center;
    }
    .products-list{
        justify-content: center;
        width: 100%;
        min-width: auto;
    }
    .products-main{
        background-repeat: repeat-y;
        padding: 150px 20px;
    }
    .products-title-holder h3{
        font-size: 45px;
        line-height: 55px;
        width: 300px;
    }
    .products-title-holder h3 span{
        font-size: 45px;
    }
}