.contact-main{
    padding: 45px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-main h3{
    color: #16ff00;
    font-size: 45px;
    font-weight: 500;
    font-family: transducer;
    max-width: 1080px;
    text-align: left;
    width: 100%;
}

.contact-main h3 span{
    color: #fff;
    font-weight: 500;
    font-family: transducer;
}

.contact-main form{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 65px;
    max-width: 1080px;
    position: relative;
}

.contact-main form input{
    width: 100%;
    background: none;
    height: 87px;
    margin-bottom: 16px;
    color: #fff;
    padding-left: 35px;
    font-size: 25px;
    font-weight: 300;
    background-color: #0A0A0A;
}

.contact-main form textarea{
    width: 100%;
    background-color: #0A0A0A;
    height: 189px;
    margin-bottom: 16px;
    color: #fff;
    padding-top: 25px;
    padding-left: 35px;
    font-size: 25px;
    resize: none;
}

.contact-main form button{
    width: 100%;
    height: 87px;
    background: #000;
    border: solid 1px #16ff00;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    color: #16ff00;
    opacity: 1;
}

.contact-main form button:hover{
    background-color: #16ff00;
    color: #000;
}

.contact-main form button.disabled{
    opacity: 0.3;
    cursor: inherit;
    opacity: 0;
}

.contact-main form .loading-form{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}

.contact-main form .loading-form span{
    font-family: transducer;
    text-align: center;
}

.loader {
    width: 48px;
    height: 48px;
    border:10px solid #000;
    border-radius: 50%;
    position: relative;
    transform:rotate(45deg);
    box-sizing: border-box;
  }
  .loader::before {
    content: "";
    position: absolute;
    box-sizing: border-box;
    inset:-10px;
    border-radius: 50%;
    border:10px solid #FF1056;
    animation: prixClipFix 2s infinite linear;
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }
    
.contact-main .email-success{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    gap: 30px;
    z-index: 1;
}

.contact-main .email-success h3{
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
}

.contact-main .email-success span{
    font-size: 15px;
    color: #999;
}

.contact-main .email-success span + span{
    font-size: 13px;
    color: #16ff00;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

.contact-main .email-success span + span:hover{
    text-decoration: underline;
}


/* Media Query */

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 769px) {
    .contact-main h3{
        font-size: 30px;
    }
}



